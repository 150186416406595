.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Anton';
  src: local('Antonn'), url(./Fonts/Anton-Regular.ttf) format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

}

.grey { 
  filter: grayscale(1);
}

.item {
  align-items: center;
  color: #FFF;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #808e9b;
}

.active {
  border: solid 1px white;
  border-radius: 40px;
  color: white;
}

.break-me {
}

.filter { 
  border-color: white !important;
  color: white !important;
}

.next {
  /* font-size: 4px; */
  height: 60px;
  position: absolute;
  right: 0;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 1000px;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  height: 60px;
  left: 0;
  position: absolute;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
